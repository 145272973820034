@use "@els/els-styleguide-core/scss/core" as *;

.c-scm-all-courses {

  @media only screen and (max-width: 1200px) {
    &__date-filter {
      &__week-nav {
        align-self: flex-start;
      }
      &__date-picker {
        align-self: flex-start;
      }
      &__date-picker-apply > *, &__back-to-current {
        padding-top: $els-space-1o2;
      }
      &__back-to-current {
        width: auto !important;
      }
    }

    &__header {
      flex-wrap: wrap;
      & > .o-els-flex-layout__item {
        align-self: flex-start;
        width: 100% !important;
      }
    }
  }

  &__date-filter {
    align-items: flex-end;

    &__week-nav {
      button {
        background-color: transparent;
        border-color: transparent;
        color: $els-color-secondary;
        visibility: visible;
      }
      span {
        font-size: $els-fs-h2;
      }

      .arrow-nav-tooltip {
        visibility: hidden;
      }
      .arrow-nav-tooltip:hover {
        visibility: visible;
      }
      .arrow-nav-tooltip:has(button:focus-visible) {
        visibility: visible;
      }
    }

    &__apply {
      background-color: $els-color-background;
      border-color: $els-color-secondary;
      color: $els-color-text;
    }

    &__error-message {
      align-self: flex-start;
      color: $els-color-warn;
    }
  }

  &__filtering-label {
    padding-right: $els-space-2x;
  }
}
