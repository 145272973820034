@use "@els/els-styleguide-core/scss/core" as *;

.c-scm-student-details-notice {
  border-color: $els-color-extended-orange-4;
  border-width: $els-thin-keyline-width;
  border-radius: $els-space-1o8;
  width: 40%;
  margin: 1rem 1rem 1rem 0;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }

  &__content {
    padding: 0.5rem !important;
  }
}
