@use "@els/els-styleguide-core/scss/core" as *;

.c-scm-assessment-performance {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;

  &__header {
    flex: 0 1 auto;
  }
  
  &__body {
    flex: 1 1 auto;
    overflow: auto;
    padding-top: $els-space-3x;
    padding-bottom: $els-space-3x;

    .c-nhe-single-best-answer__rationale-block,
    .c-nhe-multiple-answer__rationale-block {
      margin-top: 0 !important;
    }

    @include mq($from: wide) {
      padding-left: 30%;
      padding-right: 30%;
    }

    @include mq($from: desktop) {
        padding: $els-space-3x $els-space-4x 0;
        padding-left: 25%;
        padding-right: 25%;
    }

    @include mq($from: tablet) {
        padding-left: 15%;
        padding-right: 15%;
    }

    @include mq($from: mobile) {
        padding-left: 10%;
        padding-right: 10%;
    }

    @include mq($until: mobile) {
        padding-left: 5%;
        padding-right: 5%;
    }
  }
}
